import * as Yup from "yup";
import wooCommerce from "../../utils/woocommerce";
import { PaymentGatewayList, ProcessPayment } from "../../utils/helper";
import axios from "axios";
import { toast } from "react-toastify";

const orderSchema = Yup.object().shape({
  payment_method: Yup.string().required("Payment method is required"),
  payment_method_title: Yup.string().required(
    "Payment method title is required"
  ),
  set_paid: Yup.boolean().required("Set paid is required"),
  billing: Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address_1: Yup.string().required("Address 1 is required"),
    address_2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country: Yup.string().required("Country is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  }),
  shipping: Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    address_1: Yup.string().required("Address 1 is required"),
    address_2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country: Yup.string().required("Country is required"),
  }),
  line_items: Yup.array()
    .of(
      Yup.object().shape({
        product_id: Yup.number().required("Product ID is required"),
        quantity: Yup.number()
          .min(1, "Quantity must be at least 1")
          .required("Quantity is required"),
      })
    )
    .required("Line items are required"),
  shipping_lines: Yup.array()
    .of(
      Yup.object().shape({
        method_id: Yup.string().required("Shipping method ID is required"),
        method_title: Yup.string().required(
          "Shipping method title is required"
        ),
        total: Yup.string().required("Total is required"),
      })
    )
    .required("Shipping lines are required"),
});

export const createOrder = async (orderData: any) => {
  console.log("called")
  const data = orderData;
  try {
    await orderSchema.validate(data, { abortEarly: false });
    const response = await wooCommerce.post(`/orders`, data);
    console.log(response, "<<-- response")
    // const payment = await ProcessPayment(data, response.data);
    const gatewaylist = await PaymentGatewayList(data, response.data);
    return {
      orderStatus: response.data,
      gateway_list: gatewaylist,
    };
  } catch (error: any) {
    if (error instanceof Yup.ValidationError) {
      console.error("Validation errors:", error);
      return { error: true, message: error.errors };
    } else {
      // toast.error(
      //   "Please enter a valid email at checkout to use coupon code. "
      // );
      // throw new Error(
      //   "Please enter a valid email at checkout to use coupon code. "
      // );

      console.error("There was an error creating the order:", error.message);
      return { error: true, message: error.message };
    }
  }
};

export const getOrderList = async (
  page = 1,
  order_by = "date",
  customer_id = 11
) => {
  let param = {
    params: {
      page: page,
      per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
      orderby: order_by,
      customer: customer_id,
    },
  };
  try {
    const response = await wooCommerce.get("orders", param);
    return {
      data: response.data,
      total: response.headers["x-wp-total"] || 0,
      page: response.headers["x-wp-totalpages"] || 0,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};
export const getOrderNotes = async (order_id = 11) => {
  try {
    const response = await wooCommerce.get(`orders/${order_id}/notes`);
    return {
      data: response.data,
      total: response.headers["x-wp-total"] || 0,
      page: response.headers["x-wp-totalpages"] || 0,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const updateOrderStatus = async (userId: String | Number, data: any) => {
  try {
    const response = await wooCommerce.put(`orders/${userId}`, data);

    return {
      data: response.data,
      statusCode: response.status,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching account details:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};

export const getAvailablePayments = async (orderDetails: any) => {
  const data = orderDetails;

  try {
    await orderSchema.validate(data, { abortEarly: false });
    const response = await wooCommerce.post(`/orders`, data);

    const payment = await PaymentGatewayList(data, response.data);
    return { orderStatus: response.data, paymentStatus: payment, };
  } catch (error: any) {

    if (error instanceof Yup.ValidationError) {
      // console.error("Validation errors:", error.errors);
      return { error: true, message: error.errors };
    } else {
      // toast.error(
      //   "Please enter a valid email at checkout to use coupon code. "
      // );
      // throw new Error(
      //   "Please enter a valid email at checkout to use coupon code. "
      // );

      // console.error("There was an error creating the order:", error.message);
      return { error: true, message: error.message };
    }
  }
};
export const getThankyouDetails = async (orderId: any, token:any) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.GATSBY_PAYMENTFLOW_API_URL}orders/get-order?id=${orderId}&token=${token}`,
    headers: {
      Authorization: process.env.GATSBY_PAYMENT_TOKEN,
      "Content-Type": "application/json",
    },
  };

  try {
    const data = await axios.request(config);
    const wooComOrder = await wooCommerce.get(`/orders/${data.data.data.public_id}`)
    return {paymentflow:data.data.data, woocom:wooComOrder.data};
  } catch (error: any) {
    throw new Error("ERROR DURING PAYMENTFLO API", error.message);
  }
};

