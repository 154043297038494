import React, { useEffect, useState } from "react";
import { getThankyouDetails } from "../../services/woocommerceServices/Orders";
import { navigate } from "gatsby";
import Link from "../Link";

const Thankyou = (props: any) => {

  const {
    customer_email,
    public_id,
    payment_code,
    billing_address,
    shipping_address,
  } = props.records[0];
  const woocom = props.woocom[0];
  const [discount, setDiscount] = useState("");

  useEffect(() => {
    const discountIndex = woocom.meta_data?.findIndex((meta: any) => meta.key === "discount");

    if (discountIndex > -1) {
      setDiscount(parseFloat(woocom.meta_data[discountIndex].value).toFixed(2));
    } else {
      setDiscount(woocom.discount_total.toFixed(2));
    }
  }, [woocom])

  // console.log(props);
  // console.log(props.woocom[0]);
  return (
    <>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] min-h-[60vh] contain">

        <div className=" mx-auto bg-white pb-[50px]  rounded-lg ">
          <h1 className="text-3xl font-sofiaBold font-bold text-gray-900 mb-6">
            Thank you!
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <div className=" p-4 bg-bg-grey border-l-4 border-[#70b509] rounded-[12px]">
                <h2 className="text-lg font-sofiaMedium font-semibold text-green-600">
                  Your Order #{public_id} is Confirmed
                </h2>
                <p className="font-sofiaRegular text-sm text-gray-600 mt-2">
                  A confirmation email with instructions on how to complete
                  payment via {payment_code} is on the way to you. Please
                  check your email inbox in the next 1-3 minutes. Check your
                  spam or junk filter if you do not see the email within the
                  1-3 minute arrival period.
                </p>
              </div>
              <div className="mt-4">
                <h3 className="font-sofiaBold font-bold text-gray-900">
                  Order Updates
                </h3>
                <p className="font-sofiaRegular text-sm text-gray-600 mt-1">
                  You'll get shipping and delivery updates by email.
                </p>
              </div>

              <div className="mt-4">
                <h3 className="font-sofiaBold font-bold text-gray-900">
                  Customer Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                  <div>
                    <p className="font-sofiaRegular text-sm text-gray-500  font-bold">
                      Contact Information
                    </p>
                    <p className="font-sofiaRegular text-sm text-gray-700 ">
                      {customer_email}
                    </p>

                    <p className="mt-2 text-sm text-gray-500 font-sofiaMedium font-bold">
                      Shipping Address
                    </p>
                    <p className="text-sm text-gray-700 font-sofiaRegular">
                      {shipping_address.firstname} {shipping_address.lastname}
                      <br />
                      {shipping_address.address_1} <br />
                      {shipping_address.address_2} <br />
                      {shipping_address?.country_code},{shipping_address?.state_code}<br />
                      Zipcode: {woocom.shipping?.postcode}
                    </p>


                    <p className="mt-2 text-sm text-gray-500 font-sofiaMedium font-bold">
                      Shipping Method
                    </p>
                    {woocom?.shipping_lines.map((data: any) => {
                      return (
                        <p className="text-sm text-gray-700">
                          {data?.method_title}
                        </p>
                      );
                    })}
                  </div>

                  <div>
                    <p className="text-sm text-gray-500 font-sofiaMedium font-bold">
                      Billing Address
                    </p>
                    <p className="text-sm text-gray-700 font-sofiaRegular">
                      {billing_address?.firstname} {billing_address.lastname}{" "}
                      <br />
                      {billing_address?.address_1} <br />
                      {billing_address?.address_2}
                      {billing_address?.country_code},{billing_address?.state_code}<br />
                      Zipcode: {woocom.billing?.postcode}
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-bg-grey p-6 rounded-[12px]">
              <h3 className="text-lg font-sofiaBold font-bold text-gray-900">
                Order Summary
              </h3>
              <ul className="mt-4 font-sofiaRegular">
                {woocom?.line_items.map((product: any) => {
                  const original_price_index = product.meta_data.findIndex((meta: any) => meta.key === "original_price");
                  let price = "0.00";
                  if (original_price_index > -1) {
                    price = parseFloat(product.meta_data[original_price_index].value).toFixed(2);
                  } else {
                    price = product.total;
                  }

                  return (
                    <li className="flex justify-between text-gray-700 font-sofiaRegular">
                      <span>
                        {product.quantity} ×{product.name}
                      </span>
                      <span>${price}</span>
                    </li>
                  );
                })}

                <li className="flex justify-between text-gray-500 mt-2 font-sofiaRegular">
                  <span>Tax:</span>
                  <span>${woocom.total_tax}</span>
                </li>
                <li className="flex justify-between text-gray-500 mt-2 font-sofiaRegular">
                  <span>Shipping:</span>
                  {woocom?.shipping_lines.map((data: any) => {
                    return (
                      <span>
                        {data.method_title}: ${data.total}
                      </span>
                    );
                  })}
                </li>

                <li className="flex justify-between text-gray-500 mt-2 font-sofiaRegular">
                  <span>Discount:</span>
                  <span>-${discount}</span>
                </li>
                <li className="flex justify-between font-bold text-gray-900 mt-4 font-sofiaRegular">
                  <span>Total:</span>
                  <span>${woocom.total}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-6 flex justify-between items-center text-sm text-gray-600 font-sofiaMedium flex-col md:flex-row">
            <div className="flex items-center">
              <span className="mr-1">Need Help?</span>
              <Link
                href="/contact-us"
                className="text-red-600 font-sofiaMedium font-bold hover:underline"
              >
                Contact Us
              </Link>
            </div>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="bg-primary hover:bg-black btn-primary text-[18px] w-full md:w-1/4 bg-red-600 text-white font-bold py-4 px-4 rounded-[30px] mt-6 hover:bg-red-500"
            >
              Continue Shopping
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

export default Thankyou;
