import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import Thankyou from "../components/Checkout/Thankyou";
import { getThankyouDetails } from "../services/woocommerceServices/Orders";
import { navigate } from "gatsby";
import PageLoader from "../components/common/Loader/PageLoader";
import { clearCart } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
export default function thankyou() {
  const [records, setRecords] = useState<any>();
  const [woocom, setwoocom] = useState<any>();
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenpay = params.get("tokenpay");
    const idorder = params.get("idorder");
    dispatch(clearCart());
    getThankyouDetails(idorder, tokenpay)
      .then((data) => {
        setRecords([data.paymentflow]);
        setwoocom([data.woocom]);
      })
      .catch((err) => {
        console.log(err);
        navigate("/404");
      });

       // ADD KLAVIYO SCRIPT
    setTimeout(() => {
      const script = document.createElement("script");
      script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VvNYhZ`; // Ensure the company ID is correct
      script.type = "text/javascript";
      script.defer = true;
      document.getElementById("klaviyo-container")?.appendChild(script);
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(["openForm", "XGzGJW"]);
    }, 3000);
    
  }, []);

  return (
    <Layout>
       <Helmet>
        <title>Thank you</title>
      </Helmet>
      {!records && (
        <div  className=" min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader  />
        </div>
      )}
      {records && <Thankyou records={records} woocom={woocom} />}
    </Layout>
  );
}
